import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import { QuotePromo } from '@src/cms-components';
import { quotePromoMapper } from '@src/cms-components/promos/quote-promo/mappers/quote-promo.mapper';
import { LivePreviewSelect } from '@src/shared/contentstack/live-preview/widgets/live-preview-widgets';
import { LivePreviewHeader } from '@src/shared/contentstack/live-preview/widgets/live-preview-widgets.styles';
import {
  BackgroundType,
  VariantType,
} from '@src/shared/interfaces/theme.interface';
import { useCallback, useEffect, useState } from 'react';

import React from 'react';

const color_options = [
  { label: 'Light', value: 'light' },
  { label: 'Dark', value: 'dark' },
  { label: 'Mid', value: 'mid' },
];

const variant_options = [
  { label: 'Boxed', value: 'boxed' },
  { label: 'Full Width', value: 'full-width' },
];

const QuotePromoPreviewPage = (props: any) => {
  const { pageContext } = props;

  const [livePreviewData, setLivePreviewData] = useState<any>({});
  const [bgColor, setBgColor] = useState('light' as BackgroundType);
  const [variantType, setVariantType] = useState('full-width' as VariantType);

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'quote_promo',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;
    const result = response[0][0];
    setLivePreviewData(result);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return (
    <>
      <LivePreviewHeader>
        <LivePreviewSelect
          title="Background Color"
          defaultValue={bgColor}
          options={color_options}
          setSelectedItem={setBgColor}
        />
        <LivePreviewSelect
          title="Variant Type"
          defaultValue={variantType}
          options={variant_options}
          setSelectedItem={setVariantType}
        />
      </LivePreviewHeader>
      {!!livePreviewData && (
        <QuotePromo
          {...quotePromoMapper(livePreviewData, {
            backgroundColor: bgColor,
            variant: variantType,
          })}
        />
      )}
    </>
  );
};

export default QuotePromoPreviewPage;
